import React, { useState, useEffect } from 'react';
import { Suspense,lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
const Login =lazy(()=>import( './pages/login'));
const AddAdmin =lazy(()=>import( './pages/administrador/user/addAdministrador'));
const EditAdmin =lazy(()=>import( './pages/administrador/user/editAdministrador'));
const ListUser =lazy(()=>import( './pages/administrador/user/listUser'));
const ListCategory =lazy(()=>import( './pages/administrador/category/listCategory'));
const ListSubcategory =lazy(()=>import( './pages/administrador/subcategory/listSubcategory'));
const ListFrequentQuestions =lazy(()=>import( './pages/administrador/frequentQuestions/listFrequentQuestions'));
const ListContact =lazy(()=>import( './pages/administrador/contact/listcontact'));
const Dashboard =lazy(()=>import( './pages/administrador/dashboard'));
const EditTerms =lazy(()=>import( './pages/administrador/terms/editTerms'));
const ListOrder =lazy(()=>import( './pages/administrador/order/listOrder'));
const ListOrderProduct =lazy(()=>import( './pages/administrador/order/listOrderProduct'));
const EditOrder =lazy(()=>import( './pages/administrador/order/editOrder'));
const ListHome =lazy(()=>import( './pages/administrador/home/listHome'));
const ListExperience  =lazy(()=>import( './pages/administrador/experience/listExperience'));
const ListBlog =lazy(()=>import( './pages/administrador/blog/listBlog'));
const AddExperience =lazy(()=>import( './pages/administrador/experience/addExperience'));
const EditExperience =lazy(()=>import( './pages/administrador/experience/editExperience'));
const AddSubcategory =lazy(()=>import( './pages/administrador/addSubcategory'));
const AddFrequentQuestions =lazy(()=>import( './pages/administrador/addFrequentQuestions'));
const ListClient =lazy(()=>import( './pages/administrador/client/listClient'));
const AddHome =lazy(()=>import( './pages/administrador/home/addHome'));
const EditHome =lazy(()=>import( './pages/administrador/home/editHome'));
const AddBlog =lazy(()=>import( './pages/administrador/blog/addBlog'));
const EditBlog =lazy(()=>import( './pages/administrador/blog/editBlog'));

const ListBanner =lazy(()=>import( './pages/administrador/banner/listBanner'));
const AddBanner =lazy(()=>import( './pages/administrador/banner/addBanner'));
const EditBanner =lazy(()=>import( './pages/administrador/banner/editBanner'));

const ListProduct =lazy(()=>import( './pages/administrador/products/listProduct'));
const AddProduct =lazy(()=>import( './pages/administrador/products/addProduct'));
const EditProduct =lazy(()=>import( './pages/administrador/products/editProduct'));


const ListService =lazy(()=>import( './pages/administrador/services/listService'));
const AddService =lazy(()=>import( './pages/administrador/services/addService'));
const EditService =lazy(()=>import( './pages/administrador/services/editService'));

const ListWorkshop =lazy(()=>import( './pages/administrador/workshop/listWorkshop'));
const AddWorkshop =lazy(()=>import( './pages/administrador/workshop/addWorkshop'));
const EditWorkshop =lazy(()=>import( './pages/administrador/workshop/editWorkshop'));

const ListHorary =lazy(()=>import( './pages/administrador/horary/listHorary'));
const AddHorary =lazy(()=>import( './pages/administrador/horary/addHorary'));
const RecuperarCuentaAdmin =lazy(()=>import( './pages/recuperarCuentaAdmin'));
const NewPassAdmin =lazy(()=>import( './pages/newPassAdmin'));



const Template =lazy(()=>import( './pages/template'));
const AddClient =lazy(()=>import( './pages/administrador/client/addClient'));

const Home =lazy(()=>import( './pages/web/home'));
const About =lazy(()=>import( './pages/web/about'));
const Workshop =lazy(()=>import( './pages/web/workshop'));
const WorkshopDetails =lazy(()=>import( './pages/web/workshop_details'));
const Contact =lazy(()=>import( './pages/web/contact'));
const Experience =lazy(()=>import( './pages/web/experience'));
const Product =lazy(()=>import( './pages/web/product'));
const ProductDetails =lazy(()=>import( './pages/web/product_details'));
const Blog =lazy(()=>import( './pages/web/blog'));
const BlogDetails =lazy(()=>import( './pages/web/blog_details'));
const Service =lazy(()=>import( './pages/web/service'));
const ServiceDetails =lazy(()=>import( './pages/web/service_details'));
const Cart =lazy(()=>import( './pages/web/cart'));
const ExperienceDetails =lazy(()=>import( './pages/web/experience_details'));
const Search =lazy(()=>import( './pages/web/search'));
const Checkout =lazy(()=>import( './pages/web/checkout'));
const Error404 =lazy(()=>import( './pages/eror404'));
const Terms =lazy(()=>import( './pages/web/terms'));
const MiCuenta =lazy(()=>import( './pages/web/miCuenta'));
const ContenidoDetails =lazy(()=>import( './pages/web/contenido_details'));
const OrderSuccess =lazy(()=>import( './pages/web/orderSuccess'));
const RecuperarCuenta =lazy(()=>import( './pages/web/recuperarCuenta'));
const NewPass =lazy(()=>import( './pages/web/newPass'));


import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div >
      <Suspense fallback={<div>Cargando</div>}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/addAdmin" element={<AddAdmin />} />
        <Route path="/editAdmin" element={<EditAdmin />} />
        <Route path="/listAdmin" element={<ListUser />} />
        <Route path="/listCategory" element={<ListCategory />} />
        <Route path="/listBanner" element={<ListBanner />} />
        <Route path="/listSubcategory" element={<ListSubcategory />} />
        <Route path="/listFrequentQuestions" element={<ListFrequentQuestions />} />
        <Route path="/listcontact" element={<ListContact />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/addSubcategory" element={<AddSubcategory />} />
        <Route path="/addFrequentQuestions" element={<AddFrequentQuestions />} />
        <Route path="/termsCondicions" element={<EditTerms />} />
        <Route path="/listOrder" element={<ListOrder />} />
        <Route path="/listOrderProduct" element={<ListOrderProduct />} />
        <Route path="/editOrder" element={<EditOrder />} />
        <Route path="/listHome" element={<ListHome />} />
        <Route path="/listExperience" element={<ListExperience />} />
        <Route path="/listBlog" element={<ListBlog />} />
        <Route path="/addHome" element={<AddHome />} />
        <Route path="/editHome" element={<EditHome />} />
        <Route path="/addExperience" element={<AddExperience />} />
        <Route path="/editExperience" element={<EditExperience />} />
        <Route path="/addBlog" element={<AddBlog />} />
        <Route path="/editBlog" element={<EditBlog />} />

        <Route path="/listBanner" element={<ListBanner />} />
        <Route path="/addBanner" element={<AddBanner />} />
        <Route path="/editBanner" element={<EditBanner />} />

        <Route path="/listProduct" element={<ListProduct />} />
        <Route path="/addProduct" element={<AddProduct />} />
        <Route path="/editProduct" element={<EditProduct />} />

        <Route path="/listService" element={<ListService />} />
        <Route path="/addService" element={<AddService />} />
        <Route path="/editService" element={<EditService />} />

        <Route path="/listWorkshop" element={<ListWorkshop />} />
        <Route path="/addWorkshop" element={<AddWorkshop />} />
        <Route path="/editWorkshop" element={<EditWorkshop />} />

        <Route path="/listHorary" element={<ListHorary />} />
        <Route path="/addHorary" element={<AddHorary />} />
        <Route path="/recuperar-cuenta_admin" element={<RecuperarCuentaAdmin />} />
        <Route path="/solicitar_nuevo_pass_admin/clave" element={<NewPassAdmin />} />

        <Route path="/" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/workshop-details" element={<WorkshopDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/experience-details" element={<ExperienceDetails />} />
        <Route path="/search" element={<Search />} />
        <Route path="/checkaout" element={<Checkout />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cuenta" element={<MiCuenta />} />
        <Route path="/content-details" element={<ContenidoDetails />} />
        <Route path="/order-success" element={<OrderSuccess />} />
        <Route path="/recuperar-cuenta" element={<RecuperarCuenta />} />
        <Route path="/solicitar_nuevo_pass/clave" element={<NewPass />} />
        

        <Route path="/listClient" element={<ListClient />} />
        <Route path="/addClient" element={<AddClient />} />
        <Route path="/Template" element={<Template />} />
      </Routes>
      </Suspense>
      
    </div>
  );
}

export default App;
